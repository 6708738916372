/* AddService.css */
.sidebar {
    position: fixed;
    left: -140px; /* Initial position */
    width: 240px;
    height: 100vh;
    background-color: #ffffff;
    transition: right 0.3s ease; /* Transition effect for smooth sliding */
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 50px;
    border-right: 1px solid #e8d3d3;
  }
  
  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 12px;
    color: black;
    display: block;
    transition: 0.3s;
    text-align: left; /* Center the text */

  }
  
  .sidebar a:hover {
    /* color: rgb(148, 9, 9); */
    border-right: 3px solid #9f0707;
    background-color: #e5e3e3;
  }

  .second-sidebar {
    position: fixed;
    left: 0; 
    width: 310px;
    height: 100vh;
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 50px; 
  }
  
  .second-sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 12px;
    color: black;
    display: block;
    text-align: left;
    padding-left: 60px;
  }
  
  .second-sidebar a:hover {
    border-right: 3px solid #9f0707;
    background-color: #e5e3e3;
  }

  .third-sidebar {
    position: fixed;
    right: 0; 
    width: 310px;
    height: 100vh;
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 20px; 
  }
  
  .page-container {
    margin-left: 240px; /* Adjust the margin to match the width of the sidebar */
    transition: margin-left 0.3s; /* Transition effect for content shifting */
  }

  .page-container:hover .sidebar {
    left: 0; /* Open the sidebar when the cursor is over the page container */
  }
  
  .page-container:hover {
    margin-left: 0; /* Shift content to the right when the sidebar is open */
  }
  